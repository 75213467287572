<template>
    <div v-if="inited">
        <v-chip :color="cvUploading ? 'grey' : 'black'" v-if="!userCv" variant="outlined" :prepend-icon="mdiAccount" class="cursor-pointer" @click="handleClickChipUploader">
            <span>{{ cvUploading ? 'Uploading...' : 'Upload Your CV' }}</span>
            <v-progress-circular class="ml-2" size="16" v-if="cvUploading" indeterminate></v-progress-circular>
        </v-chip>
        <v-chip v-else variant="flat" color="black" :prepend-icon="mdiAccount" @click="handleOpenCv">
            <template v-slot:append>
                <v-icon @click="handleDeleteCv" class="cursor-pointer ml-1" :icon="mdiCloseCircle"></v-icon>
            </template>
            CV ({{ formatToShortDate(userCv.ts_created) }})
        </v-chip>
        <v-file-input :style="{display: 'none'}" ref="cvUploaderRef" v-model="cvFile" :rules="cvValidationRules" @update:model-value="handleUploadCv" accept="application/pdf" show-size="1024" clearable label="Upload your CV" variant="solo"></v-file-input>
    </div>
</template>

<script>
import api from '@/utils/api'
import {ref, watch} from 'vue'
import {mdiAccount, mdiCloseCircle} from '@mdi/js'
import {storeToRefs} from 'pinia'
import {useUserCvStore} from '@/stores/UserCvStore'
import {formatToShortDate} from '@/utils/dataFormatter'
export default {
    setup() {
        const cvUploaderRef = ref()
        const cvUploading = ref(false)

        const cvValidationRules = [
            (value) => {
                return !value || !value.length || value[0].size < 5 * 1024 * 1024 || 'CV size should be less than 3 MB!'
            }
        ]

        const userCvStore = useUserCvStore()
        userCvStore.init()

        const {userCv, inited} = storeToRefs(userCvStore)
        


        const cvFile = ref(null)

        const handleClickChipUploader = () => {
            if (cvUploading.value) {
                return
            }
            cvUploaderRef.value.click()
        }

        const handleOpenCv = () => {
            if (!userCv.value) {
                return
            }
            window.open(userCv.value.link, '_blank')
        }

        const handleUploadCv = async () => {
            if (!cvFile.value || !cvUploaderRef.value.isValid || cvUploading.value) {
                return
            }

            cvUploading.value = true

            const blob = new Blob([cvFile.value], {type: 'application/pdf'})

            const response = await fetch('/api/cv/upload', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/pdf'
                },
                body: blob
            })

            const json = await response.json()
            userCvStore.updateCv(json.user_cv)
            cvUploading.value = false
            cvFile.value = null
        }

        const handleDeleteCv = async (e) => {
            e.stopPropagation()
            const response = await api.post('/api/cv/delete')
            userCvStore.updateCv(null)
        }

        return {
            formatToShortDate,
            cvUploaderRef,
            cvUploading,
            cvValidationRules,
            cvFile,
            mdiAccount,
            mdiCloseCircle,
            handleClickChipUploader,
            handleUploadCv,
            handleDeleteCv,
            userCv,
            handleOpenCv,
            inited
        }
    }
}
</script>