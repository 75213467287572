<template>
    <div class="d-flex justify-center align-center">
        <div ref="resumeElement" id="resume" class="px-8 resume" :style="{'max-width': '960px'}">
            <p class="exie-text-h3">{{ cv.name }}</p>
            <p class="exie-text-body-1 text-black mt-3">{{ cv.job_title }}</p>
            <v-row class="mt-1">
                <v-col cols="3" md="3">
                    <p v-if="cv.links.portfolio">
                        <a :href="cv.links.portfolio" target="_blank" class="exie-text-body-1 text-black">Portfolio</a>
                    </p>
                    <p v-if="cv.links.linkedin">
                        <a :href="cv.links.linkedin" target="_blank" class="exie-text-body-1 text-black">LinkedIn</a>
                    </p>
                </v-col>
                <v-col cols="5" md="5" class="exie-text-body-1 text-black text-center">
                    {{ cv.email }}
                </v-col>
                <v-col cols="4" md="4" class="exie-text-body-1 text-black text-right">
                    {{ cv.location }}
                </v-col>
            </v-row>

            <v-row class="mt-3">
                <v-col cols="12">
                    <p class="exie-text-h5">
                        Professional Summary
                    </p>
                    <p class="exie-text-body-1 text-black mt-3">
                        {{ cv.summary }}
                    </p>
                </v-col>
            </v-row>

            <v-row class="mt-1">
                <v-col cols="12">
                    <div>
                        <p class="exie-text-h5 mt-5 mb-1">
                            Work History
                        </p>
                        <div v-for="experience in cv.experience" class="pb-3 no-page-break">
                            <p class="exie-text-subtitle-1 font-weight-bold">{{ experience.position }}</p>
                            <p class="exie-text-body-1">{{ experience.company }}</p>
                            <p class="exie-text-body-1">{{ experience.start_date }} &mdash; {{ experience.end_date }}</p>
                            <p class="exie-text-body-1 text-black text-pre-line mt-3 mb-5 ml-3">{{ experience.resultsList }}</p>
                        </div>
                    </div>

                    <div class="mt-5">
                        <p class="exie-text-h5 mb-1">
                            Achievements
                        </p>
                        <div class="mb-5 ml-3">
                            <p class="exie-text-body-1 text-black text-pre-line pt-5">{{ cv.achievementsList }}</p>
                        </div>
                    </div>

                    <div class="mt-10">
                        <p class="exie-text-h5 mb-1">
                            Education
                        </p>
                        <div v-for="education in cv.education" class="mt-5 mb-3 no-page-break">
                            <p class="exie-text-subtitle-1 font-weight-bold">{{ education.degree }}</p>
                            <p class="exie-text-body-1">{{ education.institution }}</p>
                            <p class="exie-text-body-1">{{ education.start_date }} &mdash; {{ education.end_date }}</p>
                            <p class="exie-text-body-1 text-black text-pre-line mt-1 ml-3">{{ education.description }}</p>
                        </div>
                    </div>

                    <div class="mt-10">
                        <p class="exie-text-h5 mb-1">
                            Skills
                        </p>
                        <div class="mb-5 ml-3">
                            <p class="exie-text-body-1 text-black text-pre-line pt-5 no-page-break">{{ cv.skillsList }}</p>
                        </div>
                    </div>

                    <div class="mt-10">
                        <p class="exie-text-h5 mb-1">
                            Languages
                        </p>
                        <div class="mb-5 ml-3">
                            <p class="exie-text-body-1 text-black text-pre-line pt-5 no-page-break">{{ cv.languagesList }}</p>
                        </div>
                    </div>

                </v-col>
            </v-row>
        </div>
    </div>
    
</template>

<script>
export default {
    props: ['cv']
}
</script>