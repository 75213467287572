<template>
    <v-dialog
        persistent
        class="align-center"
        v-model="showConsentBanner"
        id="cookie-banner"
        opacity="0.4"
    >
        <v-card
            rounded="xl"
            width="100%"
            max-width="1174px"
            min-height="280px"
            class="align-self-center py-5 px-10"
        >
            <p class="exie-text-subtitle-1 font-weight-bold mb-3">We value your privacy</p>
            <p class="exie-text-body-1">Mockin and our partners are using technologies like Cookies or third-party website tracking technologies to provide and continually improve our services, and to display advertisements according to your interests. Because we value your privacy, we are herewith asking your permission to use these technologies. You can agree and may revoke or change your consent at any time by clicking "Privacy Settings" in the footer of this page.</p>
            <div v-if="xs">
                <v-row class="ma-0">
                    <v-col cols="6" class="pa-0">
                        <v-switch
                            v-model="consentModel"
                            label="Essentials"
                            value="essentials"
                            color="black"
                            inset
                            class="mr-1"
                            density="compact"
                            disabled
                        ></v-switch>
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-switch
                            v-model="consentModel"
                            label="Advertising"
                            value="advertising"
                            color="black"
                            class="mr-1"
                            inset
                            density="compact"
                        ></v-switch>
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-switch
                            v-model="consentModel"
                            label="Advertising"
                            value="advertising"
                            color="black"
                            class="mr-1"
                            inset
                            density="compact"
                        ></v-switch>
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <v-switch
                            v-model="consentModel"
                            label="Analytics"
                            value="analytics"
                            color="black"
                            inset
                            density="compact"
                        ></v-switch>
                    </v-col>
                </v-row>
            </div>
            <div v-else class="d-flex flex-wrap mt-3">
                <v-switch
                    v-model="consentModel"
                    label="Essentials"
                    value="essentials"
                    color="black"
                    inset
                    class="mr-3"
                    density="compact"
                    disabled
                ></v-switch>
                <v-switch
                    v-model="consentModel"
                    label="Preferences"
                    value="preferences"
                    color="black"
                    inset
                    class="mr-3"
                    density="compact"
                ></v-switch>
                <v-switch
                    v-model="consentModel"
                    label="Advertising"
                    value="advertising"
                    color="black"
                    inset
                    class="mr-3"
                    density="compact"
                ></v-switch>
                <v-switch
                    v-model="consentModel"
                    label="Analytics"
                    value="analytics"
                    color="black"
                    inset
                    density="compact"
                ></v-switch>
            </div>
            <div class="d-flex flex-wrap flex-row-reverse" :class="{'justify-center': smAndDown}">
                <v-btn color="black" variant="flat" class="rounded-16 mx-2 my-1" :block="smAndDown" @click="acceptAll">
                    Accept All
                </v-btn>
                <v-btn variant="flat" class="rounded-16 mx-2 my-1" :block="smAndDown" @click="rejectAll">
                    Reject All
                </v-btn>
                <v-btn variant="flat" class="rounded-16 mx-2 my-1" :block="smAndDown" @click="savePreferences">
                    Submit Preferences
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import { useCookies } from "vue3-cookies"
import { useRoute } from 'vue-router'
export default {
    setup() {
        const route = useRoute()
        const showConsentBanner = ref(false)
        const { cookies } = useCookies()
        const cookieConsent = JSON.parse(cookies.get('consent'))
        const consentModel = ref(cookieConsent ? cookieConsent : ['essential', 'preferences', 'advertising', 'analytics'])

        const {smAndDown, xs} = useDisplay()
        const handleAccept = () => {
            console.log('accept')
        }

        const acceptAll = () => {
            consentModel.value = ['essential', 'preferences', 'advertising', 'analytics']
            savePreferences()
        }

        const rejectAll = () => {
            consentModel.value = ['essential']
            savePreferences()
        }

        const savePreferences = () => {
            cookies.set('consent', JSON.stringify(consentModel.value), '180d')
            showConsentBanner.value = false
            window.location.reload()
        }

        watch(route, (to, from) => {
            showConsentBanner.value = cookieConsent ? false : true 
            showConsentBanner.value = route.name === 'resume-center-render' ? false : showConsentBanner.value
        })

        return {showConsentBanner, handleAccept, consentModel, smAndDown, xs, acceptAll, rejectAll, savePreferences}
    }
}   
    
</script>

<style>
#cookie-banner .v-overlay__content {
    bottom: 0px;
    margin: 6px;
    width: calc(100% - 12px);
    max-width: calc(100% - 12px); 
}
#cookie-banner .v-switch__track {
    opacity: 1;
}
</style>