<template>
    <v-sheet v-if="interview">
        <v-sheet>
            <v-row>
                <v-col cols="12" sm="7" md="8">
                    <p class="exie-text-h4 mb-5">{{ getInterviewTitle(interview.type) }} interview {{ interview.status == 2 ? '(Finished)' : '' }}</p>
                </v-col>
            </v-row>
            
            <v-row class="mb-5 pb-0" v-if="!started && interview.status == 1">
                <v-col cols="12" md="7">
                    <p class="text-description text-medium-emphasis mb-1">The interview is divided into several parts and should take about 20&#8288;-&#8288;30 minutes, depending on your responses.</p>
                    <p class="text-description text-medium-emphasis">Expect a conversational style — you can ask the interviewer to repeat or rephrase questions, and you're welcome to take short breaks as needed.</p>
                </v-col>
            </v-row>
            <v-row v-if="!started && interview.status == 1">
                <v-col cols="12" md="7" v-if="interview.messages.length == 0">
                    <div>
                        <p class="exie-text-subtitle-1 font-weight-bold">Interview Language</p>
                        <v-select v-model="language" :items="languages" variant="solo-filled" flat rounded="lg" hide-details class="mt-3" color="black" @update:model-value="handleUpdateLanguage"></v-select>
                        <p class="text-description text-medium-emphasis mt-3 mb-5">Take a deep breath and let's start improving!</p>
                        <v-btn class="rounded-16 mt-3" size="large" varinat="flat" color="black" @click="handleStartInterview">Start Interview</v-btn>
                    </div>
                </v-col>
                <v-col cols="12" v-if="interview.messages.length > 0">
                    <v-btn class="rounded-16" variant="flat" size="large" color="black" @click="handleContinueInterview">Continue Interview</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="interview.messages && !started" class="mt-5 mb-5" justify="center">
                <v-col cols="12" md="8">
                    <div v-for="(message, index) in interview.messages" :key="index" class="pa-5 ma-3 rounded-lg text-pre-line" 
                    :class="{ 
                        'bg-grey-lighten-3': message.role == 'user',
                        'bg-deep-orange-lighten-5': message.role == 'assistant'
                    }">
                        <p class="exie-text-subtitle-1 font-weight-bold mb-2">{{ message.role == 'user' ? 'You' : 'Interviewer' }}</p>
                        <p class="exie-text-body-1 text-black">{{ message.content }}</p>
                    </div>
                </v-col>
            </v-row>
            <v-dialog
                v-model="started"
                max-width="1100px"
                persistent
                transition="fade-transition"
            >
                <v-card color="indigo-lighten-4" rounded="xl">
                    <v-row class="ma-0">
                        <v-col cols="12" class="text-center">
                            <span class="exie-text-h5 text-center">{{ getInterviewTitle(interview.type) }} interview</span>
                            <v-btn color="black" class="float-end" density="compact" :icon="mdiClose" variant="flat" @click="handleCloseInterviewModal"></v-btn>
                        </v-col>
                    </v-row>
                    <v-sheet class="bg-indigo-lighten-4 d-flex align-center justify-center" :style="{minHeight: '653px'}">
                        <v-row class="ma-0 px-xs-2 px-md-6 px-lg-12" v-if="starting">
                            <v-col cols="12" class="d-flex justify-center">
                                <div class="d-flex flex-column align-center justify-center" :style="{maxWidth: '500px'}">
                                    <v-progress-circular indeterminate size="43" color="black"></v-progress-circular>
                                    <p class="exie-text-h6 text-center mt-3">
                                        Sit back and get ready for growth
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-else class="ma-0 px-xs-2 px-md-6 px-lg-12">
                            <v-col cols="12" sm="6">
                                <div :style="{height: '267px'}" class="bg-white d-flex flex-column align-center justify-space-between rounded-xl" :class="{'border-lg': userSpeaking, 'border-black': userSpeaking, 'border-opacity-100': userSpeaking}">
                                    <p :style="{height: '36px', width: '100%'}" class="exie-text-h5 mb-2"><span v-if="userSpeaking" class="float-left ml-7 mt-5" >{{ Math.floor(userSpeakingCounter / 60).toString().padStart(2, '0') }}:{{ (userSpeakingCounter % 60).toString().padStart(2, '0') }} </span></p>
                                    <div>
                                        <v-avatar :image="user.photo_url" size="120"></v-avatar>
                                    </div>
                                    <p class="w-100 text-left pl-7 pb-5">{{ user.name }} 
                                        <span v-if="userSpeaking" class="float-end mr-7"><ButtonMicrophoneWaves/></span>
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <div :style="{height: '267px'}" class="bg-white d-flex flex-column align-center justify-space-between rounded-xl" :class="{'border-lg': aiSpeaking || responseProcessing, 'border-black': aiSpeaking || responseProcessing, 'border-opacity-100': aiSpeaking || responseProcessing}">
                                    <p style="height: 36px;"></p>
                                    <div>
                                        <v-avatar image="/images/mockin_avatar.svg" size="120"></v-avatar>
                                    </div>
                                    <p class="w-100 text-left pl-7 pb-5">Mockin HR 
                                        <span v-if="aiSpeaking" class="float-end mr-7"><VoiceAnimation /></span>
                                        <span v-if="responseProcessing" class="float-end mr-7"><v-progress-circular v-if="responseProcessing" indeterminate size="20" color="black"></v-progress-circular></span>
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <div class="mt-xs-1 mt-sm-14">
                                    <v-btn v-if="!userSpeaking && !aiSpeaking && !responseProcessing" @click="handleStartSpeaking" size="large" color="black" variant="flat" class="rounded-16" :class="{'w-100': xs}">Start speaking</v-btn>
                                    <v-btn v-if="aiSpeaking || responseProcessing" disabled size="large" color="black" variant="flat" class="rounded-16" :class="{'w-100': xs}">Start speaking</v-btn>
                                    <v-btn v-if="userSpeaking" @click="handleStopSpeaking" color="pink-darken-3" size="large" variant="flat" class="rounded-16" :class="{'w-100': xs}">Stop speaking</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="finished"
                persistent
                width="741px"
            >
                <v-card rounded="xl">
                    <v-card-title><v-btn :icon="mdiClose" color="black" class="float-end" density="compact" :to="{name: 'dashboard'}"></v-btn></v-card-title>
                    <div class="d-flex flex-column align-center justify-center pb-8 px-3">
                        <p class="exie-text-h5">{{ getFinalTitle() }}</p>
                        <p class="exie-text-h5">Your score is {{ interview.score ?? 0 }}/10 of the <span class="text-capitalize">{{ interview.score_grade }}</span> designer level</p>
                        <p class="mt-5 exie-text-body-1 text-medium-emphasis text-center" :style="{maxWidth: '563px'}">This is the approximate rating that shows how your responses align with the hiring manager's expectations for this role. You can review your answers and results in the History section.</p>
                        <p class="mt-5 exie-text-body-1 text-medium-emphasis text-center" :style="{maxWidth: '563px'}">Have any feedback about your interview experience or suggestions — feel free to take a quick survey. Also, you can join our community on <a href="https://t.me/+MRmcwZNJgXk0MWNi" target="_blank" class="text-decoration-none">Telegram!</a></p>
                        <v-btn class="mt-8 rounded-16" color="black" variant="flat" size="large" @click="handleTakeTheSurvey">Provide Feedback</v-btn>
                        <v-btn class="mt-3 rounded-16" variant="text" size="large" @click="finished = false">Review Results</v-btn>
                    </div>
                </v-card>
            </v-dialog>
            <NewConfetti v-if="finished" />
        </v-sheet>
        <div :style="{display: 'none'}">
            <audio id="audio" controls ref="audioRef"></audio>
        </div>
    </v-sheet>
</template>

<script>
import api from '@/utils/api'
import { useRoute } from 'vue-router'
import { ref, onUnmounted } from 'vue'
import { getInterviewTitle } from '@/utils/interviews'
import randomstring from 'randomstring'
import ButtonMicrophoneWaves from '../components/interview/ButtonMicrophoneWaves.vue'
import VoiceAnimation from '../components/interview/VoiceAnimation.vue'
import UAParser from 'ua-parser-js'
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
import { mdiClose, mdiCloseCircle } from '@mdi/js'
import { useDisplay } from 'vuetify'
import { languages } from '@/utils/interviews'
import NewConfetti from '@/components/common/NewConfetti.vue'

const browserEngineCodecs = {
    'WebKit': 'audio/mp4',
    'Gecko': 'audio/ogg',
    'Blink': 'audio/webm',
}

const USER_SPEAKING_TIME_LIMIT = 599

export default {
    components: {ButtonMicrophoneWaves, VoiceAnimation, NewConfetti},
    setup() {
        const route = useRoute()
        const id = route.params.id

        const language = ref(window.localStorage.getItem('interview_language') || 'english')

        const handleUpdateLanguage = () => {
            window.localStorage.setItem('interview_language', language.value)
        }

        const appStateStore = useAppStateStore()

        const { user } = storeToRefs(appStateStore)

        const uaParser = new UAParser()
        const {xs} = useDisplay()

        const browserEngine = ref(uaParser.getResult().engine.name)
        console.log(browserEngine.value)

        const audioRef = ref()
        const interview = ref(null)
        const started = ref(false)
        const starting = ref(false)
        const finished = ref(false)
        let finishedMarker = false

        //need for safari
        const audioDataStop = ref(false)

        let speechStream = null
        let recorder = null

        const getFinalTitle = () => {
            if (interview.value.score <= 3) {
                return 'Give it another shot!'
            } else if (interview.value.score <= 6) {
                return 'Well done!'
            } else {
                return 'Congratulations!'
            }
        }

        const initMicro = async () => {
            speechStream = await navigator.mediaDevices.getUserMedia({audio: true})
            speechStream.getTracks().forEach(track => track.stop())
        }

        initMicro()

        let wsChannelSubscription = null

        const getInterview = async () => {
            const response = await api.get(`/api/interview/get/${id}`)

            if(response.interview) {
                interview.value = response.interview
            }
        }

        getInterview()


        const userSpeaking = ref(false)
        let userSpeakingTimer = null
        const userSpeakingCounter = ref(null)
        const responseProcessing = ref(false)
        const aiSpeaking = ref(false)

        let audioContext = null

        const startSpeechStream = async () => {
            if(userSpeaking.value) {
                return
            }
            userSpeaking.value = true
            userSpeakingCounter.value = USER_SPEAKING_TIME_LIMIT
            userSpeakingTimer = setInterval(() => {
                userSpeakingCounter.value = userSpeakingCounter.value - 1
                if(userSpeakingCounter.value <= 0) {
                    handleStopSpeaking()
                    userSpeakingCounter.value = null
                    clearInterval(userSpeakingTimer)
                }
            }, 1000)
            const sess = randomstring.generate(10)
            // console.log('speech session', sess)
            speechStream = await navigator.mediaDevices.getUserMedia({audio: true})
            audioDataStop.value = false
            recorder = new MediaRecorder(speechStream, {
                mimeType: browserEngineCodecs[browserEngine.value] || 'audio/webm'
            })
            recorder.start(2000)

            recorder.addEventListener('dataavailable', event => {
                const timestamp = new Date().getTime()
                // console.log('audiodata chunk ', timestamp)
                const response = fetch('/api/interview/audio-chunk?'+ new URLSearchParams({sess: sess, ts: timestamp}), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    },
                    body: event.data
                })
            })

            recorder.addEventListener('stop', () => {
                if(audioDataStop.value) {
                    return
                }
                // console.log('audiodata stop', new Date().getTime())
                audioDataStop.value = true
                // console.log('audiodata stop')
                setTimeout(() => {
                    processUserAudio(sess)
                }, 300)
            })
        }

        const stopSpeechStream = async () => {
            if(speechStream) {
                speechStream.getTracks().forEach(track => track.stop())
            }
            if(recorder) {
                recorder.stop()
            }
            userSpeaking.value = false
            userSpeakingCounter.value = null
            clearInterval(userSpeakingTimer)
        }

        const handleStartSpeaking = (event) => {
            if(!started.value) {
                console.log('123')
                return
            }
            if(userSpeaking.value) {
                console.log('124')
                return
            }
            if(interview.value.status == 2) {
                console.log('125')
                return
            }
            if(responseProcessing.value == false && aiSpeaking.value == false) {
                console.log('start speaking')
                
                startSpeechStream()
            }
        }

        const handleStopSpeaking = (event) => {
            if(!started.value) {
                return
            }
            if(!userSpeaking.value) {
                return
            }
            
            console.log('stop speaking')
            stopSpeechStream()
        }

        onUnmounted(() => {
            if(speechStream) {
                speechStream.getTracks().forEach(track => track.stop())
            }
            if(recorder) {
                recorder.stop()
            }

            if(wsChannelSubscription) {
                wsChannelSubscription.unsubscribe()
                document.wsClient.removeSubscription(wsChannelSubscription)
                wsChannelSubscription = null
            }
        })

        const processUserAudio = async (sess) => {
            responseProcessing.value = true
            let response = await fetch(`/api/interview/finish-audio/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sess: sess,
                    codec: browserEngineCodecs[browserEngine.value] ?? null
                })
            })
            handleAudioResponse(response)
        }

        const handleStartInterview = async () => {
            // if(!audioContext) {
            audioContext = new (window.AudioContext)()
            // }
            responseProcessing.value = true
            started.value = true
            starting.value = true
            let response = await fetch(`/api/interview/start-interview/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    codec: browserEngineCodecs[browserEngine.value] ?? null,
                    language: language.value
                })
            })
            starting.value = false
            handleAudioResponse(response)
        }

        const handleContinueInterview = async () => {
            audioContext = new (window.AudioContext)()
            responseProcessing.value = true
            started.value = true
            starting.value = true
            let response = await fetch(`/api/interview/continue-interview/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    codec: browserEngineCodecs[browserEngine.value] ?? null,
                })
            })
            starting.value = false
            handleAudioResponse(response)
        }

        const handleAudioResponse = async (response) => {
            console.log(response.status)
            if(response.status != 200) {
                responseProcessing.value = false
                return
            }

            const audioResponseBlob = await response.blob()
            responseProcessing.value = false
            aiSpeaking.value = true
            audioRef.value.src = URL.createObjectURL(audioResponseBlob)
            if(started.value) {
                audioRef.value.play()
            } else {
                aiSpeaking.value = false
            }

            audioRef.value.addEventListener('ended', () => {
                console.log('audio ended')
                console.log('finishedMarker', finishedMarker)
                aiSpeaking.value = false
                if(finishedMarker) {
                    started.value = false
                    finished.value = true
                }
            }, { once: true })

        }

        const handleCloseInterviewModal = () => {
            started.value = false
            starting.value = false
            audioRef.value.pause()
            audioRef.value.currentTime = 0
            aiSpeaking.value = false
        }

        const handleTakeTheSurvey = () => {
            window.open('https://forms.gle/by5YK8DphvR5x7ZbA', '_blank')
        }

        let finishTimeout = null
        //Connect websokect interview channel
        if(document.wsClient) {
            wsChannelSubscription = document.wsClient.newSubscription('channel_interview_'+id)
            wsChannelSubscription.on('publication', (ctx) => {
                switch (ctx.data.type) {
                    case 'interview_updated':
                        console.log('interview updated')
                        interview.value.messages = ctx.data.payload.messages
                        if(interview.value.status != ctx.data.payload.status && ctx.data.payload.status == 2) {
                            console.log('a12')
                            interview.value.status = ctx.data.payload.status
                            interview.value.score = ctx.data.payload.score
                            interview.value.score_grade = ctx.data.payload.score_grade
                            finishedMarker = true
                        }
                        break
                    default:
                        break
                }
            }).subscribe()
        }


        return {
            interview,
            getInterviewTitle,
            started,
            userSpeaking,
            userSpeakingCounter,
            responseProcessing,
            aiSpeaking,
            handleStartInterview,
            handleContinueInterview,
            audioRef,
            browserEngine,
            user,
            handleStartSpeaking,
            handleStopSpeaking,
            mdiCloseCircle,
            mdiClose,
            handleCloseInterviewModal,
            finished,
            xs,
            handleTakeTheSurvey,
            handleUpdateLanguage,
            language,
            languages,
            getFinalTitle,
            starting
        }
    }
}
</script>

<style>
.mt-100p {
    margin-top: 100px;
}
</style>