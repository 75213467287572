<template>
    <v-app-bar elevation="0">
          <v-app-bar-title>
            <v-img
                      max-height="30px"
                      max-width="100px"
                      aspect-ratio="16/9"
                      position="left"
                      class="cursor-pointer"
                      src="/images/appbar-logo.svg"
                      @click="handleGoHome"
                    />
          </v-app-bar-title>
          <v-spacer></v-spacer>
          <span class="text-caption text-medium-emphasis mr-8">Beta 1.2.0</span>
    </v-app-bar>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
    setup() {
        const router = useRouter()
        const handleGoHome = () => {
            router.push('/')
        }

        return {
            handleGoHome
        }
    }
}
</script>