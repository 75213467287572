<template>
    <ResumeRenderer v-if="cv" :cv="cv" />
    <div v-if="resumeLoaded" class="resume-loaded">

    </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router"
import { ref } from 'vue'
import ResumeRenderer from '@/components/cv/ResumeRenderer.vue'
import api from '@/utils/api'
export default {
    components: {ResumeRenderer},
    setup() {
        const route = useRoute()
        const router = useRouter()

        const resumeLoaded = ref(false)
        
        const cv = ref(null)

        if (!route.query.key) {
            router.push({name: 'resume-center'})

            return
        }

        const loadResume = async () => {
            const response = await api.post('/api/cv/cv-data', {
                key: route.query.key
            })

            cv.value = response
            console.log(cv.value)

            setTimeout(() => {
                resumeLoaded.value = true
            }, 500)
        }

        loadResume()

        return {
            cv,
            resumeLoaded
        }
    }
}
</script>