import { defineStore } from 'pinia'
import api from '@/utils/api'

export const useUserCvStore = defineStore('userCvStore', {
    state: () => ({
        inited: false,
        userCv: null,
    }),
    actions: {
        async init() {
            if(this.inited) {
                return
            }
            await this.loadCv()
            this.inited = true
        },
        async loadCv() {
            const response = await api.get('/api/cv/get')
            this.userCv = response.user_cv
        },
        updateCv(cv) {
            this.userCv = cv
        }
    }
})