<template>
    <div class="mb-3"></div>
    <v-sheet>
        <v-row>
            <v-col cols="12" sm="7" md="8">
                <p class="exie-text-h4">Resume Checker</p>
            </v-col>
        </v-row>
        <v-row class="mb-5 pb-0">
            <v-col cols="12" md="7">
                <p class="exie-text-description text-medium-emphasis">Our Resume Checker helps you better highlight your skills and results in the ATS-compliant (<a href="https://en.wikipedia.org/wiki/Applicant_tracking_system" target="_blank" class="text-deep-orange">Applicant Tracking System</a>) format</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="7">
                <p class="exie-text-subtitle-1 font-weight-bold mt-3 mb-1">Step 1</p>
                <p class="exie-text-description text-medium-emphasis mb-5">Upload your resume in a PDF file under 5MB</p>
                <CVUploader />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="7">
                <div>
                    <p class="exie-text-subtitle-1 font-weight-bold mt-8 mb-1">Step 2</p>
                    <p class="exie-text-subtitle-1 text-medium-emphasis mb-5">Select resume language</p>
                    <v-select v-model="language" :items="languages" variant="solo-filled" flat rounded="lg" hide-details color="black" @update:model-value="handleUpdateLanguage"></v-select>
                    <v-btn :disabled="!userCv || cvRecommendationsLoading" variant="flat" size="x-large" color="black" class="rounded-16 mt-5" @click="handleGetCvRecommendations">Get Recommendations</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="9">
                <div class="cv_recommendations">
                    <v-progress-circular v-if="!cvRecommendations && cvRecommendationsLoading" indeterminate size="64" color="black"></v-progress-circular>
                </div>
            </v-col>
            <v-col cols="12" v-if="cvRecommendations">
                <p class="exie-text-body-1 text-black">
                    {{ cvRecommendations.comment }}
                    <br />
                    <br />
                    We've added quantitative data to help your results better reflect your impact. Feel free to modify it in your own way.
                </p>

                <v-divider class=my-5 />

                <ResumeEditor :resume="cvRecommendations.resume" />

                <v-row>
                    <v-col cols="12" md="7">
                        <p class="exie-text-body-1 text-black font-weight-bold">Why This Version is Improved:</p>
                        <ol class="exie-text-body-1 text-black mt-5 ml-5">
                            <li class="mb-3" v-for="reason in cvRecommendations.reasons">
                                {{ reason }}
                            </li>
                        </ol>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="cvRecommendations">
            <v-col cols="12" md="9">
                <v-card rounded="xl" variant="flat" class="bg-grey-lighten-4 pa-5">
                    <p class="exie-text-subtitle-1 font-weight-bold">Was this helpful?</p>
                    <p class="exie-text-body-1 mt-1">If you have any feedback on the result, please take a short survey to help us improve!</p>
                    <v-btn size="large" variant="flat" color="black" class="mt-5 rounded-16" @click="handleTakeASurvey">Take a Survey</v-btn>
                </v-card>
            </v-col>    
        </v-row>
    </v-sheet>
</template>

<script>
import CVUploader from '@/components/cv/CVUploader.vue'
import VueMarkdown from 'vue-markdown-render'
import { languages } from '@/utils/interviews'
import {useUserCvStore} from '@/stores/UserCvStore'
import {ref} from 'vue'
import {storeToRefs} from 'pinia'
import api from '@/utils/api'
import {useAppStateStore} from '@/stores/AppStateStore'
import {useRouter} from 'vue-router'
import ResumeEditor from './ResumeEditor.vue'
export default {
    components: { CVUploader, VueMarkdown, ResumeEditor },
    setup() {
        const language = ref(window.localStorage.getItem('interview_language') || 'english')
        const userCvStore = useUserCvStore()
        const {userCv} = storeToRefs(userCvStore)

        const router = useRouter()
        const appStateStore = useAppStateStore()
        const {user} = storeToRefs(appStateStore)
        if(!user.value.features.feature_resume_recommendations) {
            router.push({name: 'dashboard'})
        }

        const cvRecommendations = ref(null)
        const cvRecommendationsLoading = ref(false)

        const handleUpdateLanguage = () => {
            window.localStorage.setItem('interview_language', language.value)
        }

        const handleGetCvRecommendations = async () => {
            if(!userCv.value) {
                return
            }
            cvRecommendations.value = null
            cvRecommendationsLoading.value = true
            const response = await api.post('/api/cv/recommendations', {
                language: language.value
            })
            cvRecommendationsLoading.value = false
            cvRecommendations.value = response
        }

        const handleTakeASurvey = () => {
            window.open('https://forms.gle/qkzraLJoyCtCRvx9A', '_blank')
        }

        return {
            language,
            languages,
            handleUpdateLanguage,
            userCv,
            handleGetCvRecommendations,
            cvRecommendations,
            cvRecommendationsLoading,
            handleTakeASurvey
        }
    }
}
</script>

<style scoped>
.cv_recommendations :deep(p) {
    margin-top: 12px;
    margin-bottom: 24px;
}
.cv_recommendations :deep(ul) {
    margin-left: 24px;
    margin-bottom: 24px;
}
.cv_recommendations :deep(ol) {
    margin-left: 24px;
    margin-bottom: 24px;
}
.cv_recommendations :deep(hr) {
    margin-top: 24px;
    margin-bottom: 24px;
}
</style>