<template>
    <div v-show="!downloadingResume">
        <p class="exie-text-h4">{{ cv.name }}</p>
        <p class="exie-text-body-1 text-black mt-3">{{ cv.job_title }}</p>
        <p class="exie-text-h5 mt-5">
            Contacts
        </p>
        <v-row class="mt-1">
            <v-col cols="12" md="4" class="py-0">
                <v-text-field ref="portfolioRef" v-model="cv.links.portfolio" validate-on="eager" :rules="[rules.url]" label="Portfolio link" variant="solo-filled" rounded="lg"></v-text-field>
                <v-text-field ref="linkedinRef" v-model="cv.links.linkedin" validate-on="eager" :rules="[rules.url]" label="Your LinkedIn" variant="solo-filled" rounded="lg"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
                <v-text-field v-model="cv.email" label="Email" variant="solo-filled" rounded="lg"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="text-right py-0">
                <v-text-field v-model="cv.location" label="Location" variant="solo-filled" rounded="lg"></v-text-field>
            </v-col>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12">
                <p class="exie-text-h5">
                    Professional Summary
                </p>
                <p class="exie-text-body-1 text-black mt-3">
                    <v-textarea v-model="cv.summary" variant="solo-filled" rounded="lg" no-resize></v-textarea>
                </p>
            </v-col>
        </v-row>

        <v-row class="mt-1">
            <v-col cols="12">
                <div>
                    <p class="exie-text-h5 mt-5 mb-5">
                        Work History
                    </p>
                    <div v-for="experience in cv.experience" class="mb-5 no-page-break">
                        <p class="exie-text-subtitle-1 font-weight-bold">{{ experience.position }}</p>
                        <p class="exie-text-body-1 text-black">{{ experience.company }}</p>
                        <p class="exie-text-body-1 text-black">{{ experience.start_date }} &mdash; {{ experience.end_date }}</p>
                        <p class=mt-3>
                            <v-textarea 
                                placeholder="Connect your design work to measurable business results or user outcomes (e.g., increased engagement, higher retention rates, reduced support tickets)." 
                                v-model="experience.resultsList" variant="solo-filled" rounded="lg" no-resize hide-details
                            ></v-textarea>
                        </p>
                    </div>
                </div>

                <div class="mt-5">
                    <p class="exie-text-h5 mb-3">
                        Education
                    </p>
                    <div v-for="education in cv.education" class="mb-5">
                        <p class="exie-text-subtitle-1 font-weight-bold">{{ education.degree }}</p>
                        <p class="exie-text-body-1 text-black">{{ education.institution }}</p>
                        <p class="exie-text-body-1 text-black">{{ education.start_date }} &mdash; {{ education.end_date }}</p>
                        <p class=mt-3>
                            <v-textarea v-model="education.description" variant="solo-filled" rounded="lg" no-resize hide-details></v-textarea>
                        </p>
                    </div>
                </div>

            </v-col>
            <v-col cols="12">
                <div>
                    <p class="exie-text-h5 mb-5">
                        Skills
                    </p>
                    <div class="mb-5 ml-1">
                        <p class=mt-3>
                            <v-textarea v-model="cv.skillsList" variant="solo-filled" rounded="lg" no-resize hide-details></v-textarea>
                        </p>
                    </div>
                </div>

                <div>
                    <p class="exie-text-h5 mt-5 mb-5">
                        Languages
                    </p>
                    <div class="mb-5 ml-1">
                        <p class=mt-3>
                            <v-textarea v-model="cv.languagesList" variant="solo-filled" rounded="lg" no-resize hide-details></v-textarea>
                        </p>
                    </div>
                </div>
                <div>
                    <p class="exie-text-h5 mt-5 mb-5">
                        Achievments
                    </p>
                    <div class="mb-5">
                        <p class=mt-3>
                            <v-textarea v-model="cv.achievementsList" variant="solo-filled" rounded="lg" no-resize hide-details></v-textarea>
                        </p>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>

    <v-divider class=my-5 />

    <v-btn size="large" flat color="black" class="mb-8 mt-5 rounded-16" @click="handleDownloadResume">Download PDF</v-btn>

    <a href="" :style="{display: 'none'}" ref="resumeDownloadLink" download="My Resume by Mockin"></a>

    <v-snackbar
      v-model="error"
      :timeout="3500"
      color="error"
    >{{ error }}</v-snackbar>

    
</template>
<script>
import html2pdf from 'html2pdf.js'
import { ref } from 'vue'
import isUrl from 'is-url'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import { useRouter } from 'vue-router'
export default {
    props: ['resume'],
    setup(props) {
        const router = useRouter()
        const cv = ref(null)

        const {mdAndDown} = useDisplay()

        const portfolioRef = ref()
        const linkedinRef = ref()

        const resumeDownloadLink = ref()

        const error = ref(null)

        const rules = {
            url: value => {
                if(!value) {
                    return true
                }
                return isUrl(value) || 'Invalid URL. The URL must start with the http or https protocol.'
            }
        }

        const buildResume = () => {
            cv.value = props.resume
            for (let i = 0; i < cv.value.experience.length; i++) {
                cv.value.experience[i].resultsList = ''
                for (let j = 0; j < cv.value.experience[i].results.length; j++) {
                    cv.value.experience[i].resultsList += `• ${cv.value.experience[i].results[j]}`
                    if (j != cv.value.experience[i].results.length - 1) {
                        cv.value.experience[i].resultsList += '\r\n'
                    }
                }
            }

            cv.value.skillsList = ''
            for (let i = 0; i < cv.value.skills.length; i++) {
                cv.value.skillsList += `• ${cv.value.skills[i]}`
                if(i != cv.value.skills.length - 1) {
                    cv.value.skillsList += '\r\n'
                }
            }
            cv.value.languagesList = ''
            for (let i = 0; i < cv.value.languages.length; i++) {
                cv.value.languagesList += `• ${cv.value.languages[i]}`
                if(i != cv.value.languages.length - 1) {
                    cv.value.languagesList += '\r\n'
                }
            }
            cv.value.achievementsList = ''
            for (let i = 0; i < cv.value.achievements.length; i++) {
                cv.value.achievementsList += `• ${cv.value.achievements[i]}`
                if(i != cv.value.achievements.length - 1) {
                    cv.value.achievementsList += '\r\n'
                }
            }
        }

        buildResume()
        const resumeElement = ref()
        const downloadingResume = ref(false)
        const handleDownloadResume = async () => {
            const response = await fetch('/api/cv/generate-pdf', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cv: cv.value
                })
            })

            const blob = await response.blob()
            const file = window.URL.createObjectURL(blob)
            resumeDownloadLink.value.href = file
            resumeDownloadLink.value.click()

            return
            error.value = null
            if(!linkedinRef.value.isValid || !portfolioRef.value.isValid) {
                error.value = 'Please correct all the errors in the resume form.'
                return
            }
            downloadingResume.value = true
            var opt = {
                margin:       0,
                filename:     'My new resume by Mockin.pdf',
                outputType:   'pdf',
                pagebreak:    { mode: ['css',], avoid: ['.no-page-break'] },
                html2canvas:  { scale: 3 },
                jsPDF:        { unit: 'cm', format: 'letter', orientation: 'portrait' }
            }
            await html2pdf().set(opt).from(resumeElement.value).save()
            downloadingResume.value = false
        }
        return {
            resumeElement,
            handleDownloadResume,
            downloadingResume,
            cv,
            rules,
            portfolioRef,
            linkedinRef,
            error,
            mdAndDown,
            resumeDownloadLink
        }
    }
}
</script>

<style>
</style>